<template>
    <div class="section-block">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-sm-7 col-12">
                    <div class="section-heading mt-15">
                        <h4>Let's Talk about Your Business</h4>
                        <div class="section-heading-line-left"></div>
                    </div>
                    <div class="contact-form-box mt-30">

                        {{ isSending }}
                        
                        <form
                            class="contact-form"
                            @submit.prevent="sendContact"
                        >
                            <div class="row">
                                <div class="col-md-12">
                                    <input
                                        type="text"
                                        name="subject"
                                        placeholder="Subject"
                                        v-model="contact.subject"
                                        required
                                    />
                                </div>
                                <div class="col-md-6 col-sm-6 col-12">
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        v-model="contact.name"
                                    />
                                </div>
                                <div class="col-md-6 col-sm-6 col-12">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="E-mail"
                                        v-model="contact.email"
                                        required
                                    />
                                </div>
                                <div class="col-md-12">
                                    <textarea
                                        name="message"
                                        placeholder="Message"
                                        v-model="contact.message"
                                        required
                                    />
                                </div>
                                <div class="col-md-12 mb-30">
                                    <div class="center-holder">
                                        <button type="submit">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-5 col-sm-5 col-12">
                    <div class="contact-info-box">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-12">
                                <div class="contact-info-section">
                                    <div class="row">
                                        <div
                                            class="
                                                col-md-2 col-sm-2 col-4
                                                center-holder
                                            "
                                        >
                                            <i class="fa fa-phone"></i>
                                        </div>
                                        <div class="col-md-10 col-sm-10 col-8">
                                            <h4>Call Us</h4>
                                            <p>(+233) 50-619-833</p>
                                            <p>(+44) 795-197-7950</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                                <div class="contact-info-section">
                                    <div class="row">
                                        <div
                                            class="
                                                col-md-2 col-sm-2 col-4
                                                center-holder
                                            "
                                        >
                                            <i class="fa fa-envelope-open"></i>
                                        </div>
                                        <div class="col-md-10 col-sm-10 col-8">
                                            <h4>Email</h4>
                                            <p>s4lconsult@gmail.com</p>
                                            <p>info@s4lconsult.com</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                                <div class="contact-info-section">
                                    <div class="row">
                                        <div
                                            class="
                                                col-md-2 col-sm-2 col-4
                                                center-holder
                                            "
                                        >
                                            <i class="fa fa-map-marker"></i>
                                        </div>
                                        <div class="col-md-10 col-sm-10 col-8">
                                            <h4>Our Location - Ghana</h4>
                                            <p>
                                                Vanguard Enclave, Amasaman,
                                                Accra
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                                <div class="contact-info-section">
                                    <div class="row">
                                        <div
                                            class="
                                                col-md-2 col-sm-2 col-4
                                                center-holder
                                            "
                                        >
                                            <i class="fa fa-globe"></i>
                                        </div>
                                        <div class="col-md-10 col-sm-10 col-8">
                                            <h4>Our Location - UK</h4>
                                            <p>
                                                12 Centrillion Point, 2 Masons
                                                Avenue, Croydon. UK
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                                <div class="contact-info-section">
                                    <div class="row">
                                        <div
                                            class="
                                                col-md-2 col-sm-2 col-4
                                                center-holder
                                            "
                                        >
                                            <i class="fa fa-clock-o"></i>
                                        </div>
                                        <div class="col-md-10 col-sm-10 col-8">
                                            <h4>Working Hours</h4>
                                            <p>Mon-Sat 09:00 - 19:00</p>
                                            <p>Sun 10:00 - 13:00</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { addDoc, collection, getFirestore } from "firebase/firestore";
const db = getFirestore();
export default {
    name: "OurContact",
    data() {
        return {
            contact: {
                name: "",
                email: "",
                subject: "",
                message: "",
            },
            isSending: false,
        };
    },
    methods: {
        async sendContact() {
            // validate the data

            if (this.contact.email == "") {
                return this.$toast.error("Please enter your email");
            }

            if (this.contact.subject == "") {
                return this.$toast.error("Please enter your subject");
            }

            if (this.contact.message == "") {
                return this.$toast.error("Please enter your message");
            }

            this.isSending = true;
            
            const ref = collection(db, "contacts");
            await addDoc(ref, this.contact);

            this.isSending = false;

            this.contact = {
                name: "",
                email: "",
                subject: "",
                message: "",
            };

            this.$toast.success("Message sent successfully");

        },
    },
};
</script>